'use client';

import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { stack } from '@/uiPrimitives/layout/stack';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import {
	accordionContentPadding,
	delayIconTransitionHover,
} from './Accordion.css';
import { icon } from '@/uiPrimitives/media/icon';
import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { Collapse } from '../Collapse';
import { useId } from 'react';
import { AccordionItemType } from './Accordion.types';
import { useAccordion } from './AccordionProvider.client';

export const AccordionItem = ({
	className,
	item: { summaryElement, contentElement },
	Heading = 'h3',
	index,
}: {
	className?: string;
	item: AccordionItemType;
	Heading?: 'h2' | 'h3' | 'h4';
	index: number;
}) => {
	const { openedItems, dispatchOpenedItems } = useAccordion();

	const isOpened = openedItems.has(index);

	const id = useId();

	return (
		<div key={index} className={className}>
			<Heading className={classes(stack({}))}>
				<button
					aria-controls={id}
					aria-expanded={isOpened}
					onClick={() => {
						dispatchOpenedItems({
							type: 'toggle',
							index,
						});
					}}
					className={classes(
						shelf({
							gap: '-1 | small',
							gridTemplateColumns: 'minmax(0, 1fr) auto',
							alignItems: 'baseline',
						}),
						box({
							paddingBlock: '1 | h3',
						}),
						textStyle({
							color: 'positiveBlue',
							fontWeight: isOpened ? 'bold' : undefined,
						})
					)}
					{...addTestSelector(`uiAccordionItem-${index}`)}
				>
					<div className={classes(textBlock({}))}>{summaryElement}</div>
					<i
						className={classes(
							delayIconTransitionHover,
							icon({
								icon: chevronIcon,
								adjustIcon: 'rotate90deg',
								flip: isOpened ? 'horizontal:hover' : 'horizontalNone:hover',
								blockSize: '1ex',
							})
						)}
					/>
				</button>
			</Heading>
			<Collapse id={id} isOpened={isOpened}>
				<div
					className={classes(accordionContentPadding, textBlock({}))}
					{...addTestSelector(`uiAccordionContent-${index}`)}
				>
					{contentElement}
				</div>
			</Collapse>
		</div>
	);
};
