'use client';

import {
	Dispatch,
	PropsWithChildren,
	createContext,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import { AccordionItemType } from './Accordion.types';
import { produce } from 'immer';

const AccordionContext = createContext<
	| {
			openedItems: Set<number>;
			dispatchOpenedItems: Dispatch<Parameters<typeof openedItemsReducer>[1]>;
	  }
	| undefined
>(undefined);

const openedItemsReducer = produce(
	(
		openedItemsDraft: Set<number>,
		action:
			| { type: 'toggle'; index: number }
			| { type: 'openAll'; itemsCount: number }
			| { type: 'closeAll' }
	) => {
		switch (action.type) {
			case 'toggle':
				if (openedItemsDraft.has(action.index)) {
					openedItemsDraft.delete(action.index);
					return;
				}

				openedItemsDraft.add(action.index);
				return;

			case 'openAll':
				for (let index = 0; index < action.itemsCount; index += 1) {
					openedItemsDraft.add(index);
				}
				return;

			case 'closeAll':
				openedItemsDraft.clear();
				return;
		}
	}
);

export const AccordionProvider = ({
	children,
	accordionItems,
}: PropsWithChildren<{ accordionItems: Array<AccordionItemType> }>) => {
	const openedItemsIndexSet: Set<number> = useMemo(
		() =>
			accordionItems.reduce(
				(acc, { openOnMount }, index) => (openOnMount ? acc.add(index) : acc),
				new Set<number>()
			),
		[accordionItems]
	);

	const [openedItems, dispatchOpenedItems] = useReducer(
		openedItemsReducer,
		openedItemsIndexSet
	);

	return (
		<AccordionContext.Provider value={{ openedItems, dispatchOpenedItems }}>
			{children}
		</AccordionContext.Provider>
	);
};

export const useAccordion = () => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error('useAccordion must be used within a AccordionProvider');
	}

	return context;
};
