import { classes } from '../base/classes.helpers';
import { interactiveBase, interactiveSprinkles } from './interactive.css';

/**
 * To make interactive elements (`button`, `a` etcetera) styling react to interaction.
 *
 * For instance: on `:active` translate the element `1px` down to show it is being pressed.
 */
export const interactive = (
	props: Parameters<typeof interactiveSprinkles>[0]
) => classes(interactiveBase, interactiveSprinkles(props));
