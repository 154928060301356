'use client';

import { pushYouTubePlayingEvent } from '@/globals/analytics/events/pushYouTubePlayingEvent';
import { Translation } from '@/globals/translate/translations';
import {
	addYouTubePostMessageListener,
	postYouTubeInitialEvents,
} from '@/sections/youTube/youTubeEvents';
import { posix as posixPath } from 'path';

import { useEffect, useRef } from 'react';
import { getYoutubeId } from './getYoutubeId';

export const YouTubeClient = ({
	src,
	origin,
	className,
	title,
}: {
	src: string;
	origin: string;
	title: Translation<'YouTube video'>;
	className?: string;
}) => {
	const iFrameRef = useRef<HTMLIFrameElement>(null);
	const unplayedRef = useRef(true);
	useEffect(() => {
		const iFrame = iFrameRef.current;
		if (iFrame == null) return;

		const abortController = new AbortController();

		iFrame.addEventListener(
			'load',
			() => {
				const targetWindow = iFrame.contentWindow;
				if (targetWindow == null) return;

				addYouTubePostMessageListener(
					targetWindow,
					'playing',
					() => {
						if (unplayedRef.current) {
							unplayedRef.current = false;
							pushYouTubePlayingEvent();
						}
					},
					{
						signal: abortController.signal,
						once: true,
					}
				);
				postYouTubeInitialEvents(targetWindow);
			},
			{ signal: abortController.signal, once: true }
		);

		return () => {
			abortController.abort();
		};
	}, []);

	// Query string parameters:
	// enablejsapi=1 => allows us to communicate with the iFrame through postMessage
	// disablekb=1 => causes the player to not respond to keyboard controls, needed because it interferes with standard a11y-tools keybindings
	// rel=0 => disables related video's from other channels after the video is finished
	// origin=... => is for security reasons so Youtube can reject postMessages sent from a different origin
	// autoplay=0|1 => autostart playing, needs allow="autoplay" as iframe attribute to function
	if (src == null) return null;

	let iframeURL;
	try {
		const youtubeId = getYoutubeId(src);
		iframeURL = new URL(
			posixPath.join('https://www.youtube.com/embed', youtubeId)
		);
	} catch (error) {
		return null;
	}
	iframeURL.searchParams.set('enablejsapi', '1');
	iframeURL.searchParams.set('disablekb', '1');
	iframeURL.searchParams.set('rel', '0');
	iframeURL.searchParams.set('origin', origin);

	return (
		<iframe
			ref={iFrameRef}
			src={iframeURL.href}
			title={title}
			allowFullScreen
			allow="autoplay"
			className={className}
		/>
	);
};
